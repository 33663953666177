import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Depositepayment.css";
import { logoutUser } from "../../Redux/Slices/authSlice";
import copy from "../../Media/copy.svg";
import { API } from "../../API/API";
import { toast } from "react-toastify";

function Depositpayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [utr, setUtr] = useState("");
  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const [bankDetails, setbankDetails] = useState([]);

  const incrementValue = (amount) => {
    setValue(parseInt(value) + amount);
  };

  const Bank_Detail_API = async () => {
    try {
      let responce = await API.get(
        `adminBankDetails?uid=${userId}`,

        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Bank Details", responce?.data?.data[0][0]);
      setbankDetails(responce?.data?.data[0][0]);
    } catch (e) {
      console.log("error while calling fuction sell", e);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  async function handleSubmit(e) {
    try {
      if (!value || value <= 0) {
        toast.error("Invalid Amount");
        return;
      } else if (!utr) {
        toast.error("Invalid UTR No.");
        return;
      }
      let responce = await API.post(
        `/bankFundRequest`,
        {
          uid: userId,
          amount: value,
          txn: utr,
          bankid: "",
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      //console.log("Submit API", responce);
      if (responce.data.data === "success") {
        toast.success("Requested Successfully !!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(responce.data.data);
      }
    } catch (e) {
      console.log("error while calling fuction sell", e);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  }

  useEffect(() => {
    Bank_Detail_API();
  }, []);

  const HandleAmount = async (e) => {
    let value = e.target.value;
    const newValue = value.replace(/[^0-9]/gi, "");
    setValue(newValue);
  };

  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3 depositesection">
      <div className="row mx-0">
        <div className="col-md-12 p-3 seperator">
          <div className="d-flex align-items-center justify-content-between pb-2 mb-2 mt-2 account-details">
            <p className="mb-0 fw-bold textright"> Account Number </p>
            <div className="d-flex align-items-center idcopy">
              <p className="mb-0">{bankDetails?.btcaddress}</p>
              <i
                className="fa fa-clone ms-2"
                aria-hidden="true"
                style={{ color: "#fdd70e" }}
              ></i>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between pb-2 mb-2 account-details">
            <p className="mb-0 fw-bold textright">Holder Name</p>
            <div className="d-flex align-items-center idcopy">
              <p className="mb-0 copy">{bankDetails?.btcname} </p>
              <i
                className="fa fa-clone ms-2 iconcopy"
                aria-hidden="true"
                style={{ color: "#fdd70e" }}
              ></i>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between pb-2 mb-2 account-details">
            <p className="mb-0 fw-bold textright">Bank Name</p>
            <div className="d-flex align-items-center idcopy">
              <p className="mb-0">{bankDetails?.Bank_Name}</p>
              <i
                className="fa fa-clone ms-2"
                aria-hidden="true"
                style={{ color: "#fdd70e" }}
              ></i>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between pb-2 mb-2 account-details">
            <p className="mb-0 fw-bold textright">IFSC Code</p>
            <div className="d-flex align-items-center idcopy">
              <p className="mb-0 me-1">{bankDetails?.ifsc}</p>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 mb-3 px-1">
          <a href="" className="text-animate">
            For Payment Related issue click here
          </a>
        </div> */}
        <div className="col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
          <div className="card paymentcard mb-3">
            <div className="card-body">
              <div className="heading mb-2">REQUEST YOUR DEPOSIT PAYMENT</div>
              {/* <div className="inputfilegroup text-start mb-2">
                <label htmlFor="picture__input " className="picture">
                  Upload your Payment Proof Here
                  <span class="picture__image"></span>
                </label>
                <div className="inputimage">
                  <i class="fa fa-upload" aria-hidden="true"></i>
                  <input
                    type="file"
                    id="picture__input"
                    name="paymentReceipt"
                    accept=".jpg, .jpeg, .png"
                  />
                </div>
              </div> */}
              <div className="normalinput text-start mb-2">
                <label htmlFor="utr">UTR Number</label>
                <div className="inputimage">
                  <input
                    type="text"
                    formcontrolname="utr"
                    class="form-control custom shadow-none border-0"
                    placeholder="Enter UTR Number"
                    value={utr}
                    maxLength={20}
                    onChange={(e) => setUtr(e.target.value)}
                  />
                </div>
              </div>
              <div className="normalinput text-start mb-2">
                <label htmlFor="amount">Amount</label>
                <div className="inputimage">
                  <input
                    type="text"
                    formcontrolname="utr"
                    class="form-control custom shadow-none border-0"
                    value={value}
                    maxLength={10}
                    onChange={(e) => HandleAmount(e)}
                    placeholder="Enter Amount"
                  />
                </div>
              </div>
              <div className="row  g-3 mainrow">
                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(100)}
                  >
                    + 100
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3 ">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(1000)}
                  >
                    + 1000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(2000)}
                  >
                    + 2000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(5000)}
                  >
                    + 5000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(10000)}
                  >
                    + 10000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(25000)}
                  >
                    + 25000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(50000)}
                  >
                    + 50000
                  </button>
                </div>
                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(100000)}
                  >
                    + 100000
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="submit btn"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="card notcard mb-3">
            <div className="card-body">
              <h4 class="text-danger fw-bold mb-0 main-heading">Note:</h4>
              <ul class="notes mt-3 ms-3 mb-0 mt-0">
                {/* <li class="mb-1">
                  {" "}
                  AFTER DEPOSIT, UPLOAD SLIP, WRITE YOUR DEPOSIT AMOUNT.
                </li> */}
                <li class="mb-1"> COPY &amp; ENTER THE UTR NUMBER. </li>
                <li class="mb-1">
                  {" "}
                  CLICK SUBMIT BUTTON &amp; RECIEVE POINTS &amp; BONUS
                  INSTANTLY.{" "}
                </li>
                <li class="mb-1">
                  {" "}
                  NEFT RECIEVING TIME VARIES FROM 40 MINUTES TO 1 HOUR.
                </li>
                <li class="mb-1">
                  {" "}
                  SITE IS NOT RESPONSIBLE FOR MONEY DEPOSITED TO OLD, IN-ACTIVE
                  OR CLOSED ACCOUNTS.{" "}
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="card notcard mb-3">
            <p className="text-white mb-0 py-2">No Data Found</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default Depositpayment;
