import React from "react";
import slider from "../../Media/slider.png";
// import slider1 from "../../Media/slider1.png";
import slider2 from "../../Media/slider2.png";
import slider3 from "../../Media/slider3.png";
import slider4 from "../../Media/slider4.png";
import slider5 from "../../Media/slider5.png";
import slider6 from "../../Media/slider6.png";
import slider7 from "../../Media/slider7.png";
import Slider from "react-slick";
import "./Slider.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const SliderH = () => {
  const settings = {
    
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    prevArrow: false, 
    nextArrow: false, 
    

   
  };
  
  return (
    
      <div className="card slider-card bg-black">
      <Slider {...settings}>
      
      <img src={slider} alt="img" className=" slider-img img-fluid" />
        {/* <img src={slider1} alt="img" className="slider-img img-fluid" /> */}
        <img src={slider2} alt="img" className="slider-img img-fluid" />
        <img src={slider4} alt="img" className="slider-img img-fluid" />
        <img src={slider7} alt="img" className="slider-img img-fluid" />
        <img src={slider5} alt="img" className="slider-img img-fluid" />
        <img src={slider6} alt="img" className="slider-img img-fluid" />
        <img src={slider3} alt="img" className="slider-img img-fluid" />
       
  
      </Slider>

      </div>
    


  )
}

export default SliderH
