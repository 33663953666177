import React from "react";
import MatchesDetail from "../MatchesDetail/MatchesDetail";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Home.css";
import DashImage from "./DashImage";
import watsappiconimg from "../../Media/whatsap.png";

const Home = () => {
  return (
    <div className="col-lg-10 home mainHome">
      <div className="row home p">
        <MatchesDetail />
        <RightSidebar />
      </div>
      <DashImage />
      <a
        href="https://wa.me/+9779812577726"
        className="watsappicon position-fixed "
        style={{ width: "60px", height: "60px", right: "15px", bottom: "50px" }}
      >
        <img
          className="sport-img ms-1 w-100 h-100 rounded-pill "
          src={watsappiconimg}
          alt="a"
        />
      </a>
    </div>
  );
};

export default Home;
